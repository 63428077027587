<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>片库</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 剧名： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">剧名：</a-col>
          <a-col :span="18">
            <a-input v-model:value="name" class="tool-item" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <!-- 评级： -->
      <!-- <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">评级：</a-col>
          <a-col :span="18">
            <a-select v-model:value="gradeID" class="tool-item" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in Grades" :key="item.text" :value="item.text">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col> -->
      <!-- 上架状态： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">上架状态：</a-col>
          <a-col :span="18">
            <a-select v-model:value="OnShelfStatus" class="tool-item" placeholder="请选择">
              <a-select-option :value="-1">全部</a-select-option>
              <a-select-option v-for="item in OnShelfStatusOptions" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 首页展示： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">首页展示：</a-col>
          <a-col :span="18">
            <a-select v-model:value="isShow" class="tool-item" placeholder="请选择">
              <a-select-option :value="-1">全部</a-select-option>
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">是</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 广告解锁： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">广告解锁：</a-col>
          <a-col :span="18">
            <a-select v-model:value="isAdUnlock" class="tool-item" placeholder="请选择">
              <a-select-option :value="-1">全部</a-select-option>
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">是</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 排序： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">排序：</a-col>
          <a-col :span="18">
            <a-select v-model:value="sortKey" class="tool-item" placeholder="请选择">
              <a-select-option v-for="item in sortOptions" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 剧情分类： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">剧情分类：</a-col>
          <a-col :span="18">
            <a-select v-model:value="plotType" class="tool-item" placeholder="请选择">
              <a-select-option v-for="item in PlotTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 16px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 多选组件 -->
  <TableCheckbox
    sourceKey="id"
    :dataSource="dataSource"
    :selectedRowKeys="selectedRowKeys"
    :selectedRows="selectedRows"
    :pagination="pagination"
    :isLoading="isLoading"
  >
    <!-- 按钮文案 -->
    <template #checkbox>
      <a-button style="margin: 0 10px 0 10px;" @click="batchShelves('down')">下架</a-button>
      <a-button @click="batchShelves('up')">上架</a-button>
    </template>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 剧名 -->
        <template v-if="column.key === 'name'">
          <img class="cover-img" :src="$pub.CDN_URL(record && record.s_cover[0].url)">
          <span class="cover-title">{{ record.remark_name }}</span>
          <a-tooltip>
            <template #title>{{ record.name }}</template>
            <info-circle-outlined style="margin-left: 6px; cursor: pointer;" />
          </a-tooltip>
        </template>
        <!-- 状态 -->
        <template v-if="column.key === 'status'">
          {{ record.status === 1 ? '在架' : '下架' }}
        </template>
        <!-- 首页展示 -->
        <template v-if="column.key === 'is_show'">
          {{ record.is_show === 1 ? '是' : '否' }}
        </template>
        <!-- 广告解锁 -->
        <template v-if="column.key === 'ad_unlock'">
          {{ record.ad_unlock === 1 ? '是' : '否' }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchInfo(record)">基本信息</a>
          <a class="operation-item" @click="touchSeries(record)">剧集</a>
          <a class="operation-item" @click="touchForward(record)">转发设置</a>
        </template>
      </template>
    </a-table>
  </TableCheckbox>
  <!-- 基本信息 -->
  <BaseInfo ref="RefBaseInfo" @success="getData"></BaseInfo>
  <!-- 剧集 -->
  <Series ref="RefSeries"></Series>
  <!-- 转发设置 -->
  <Forward ref="RefForward" @success="getData"></Forward>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import Series from './components/Series'
import BaseInfo from './components/BaseInfo'
import Forward from './components/Forward'
import TableCheckbox from '@/components/TableCheckbox'
import { Grades, OnShelfStatusOptions, PlotTypes } from '@/utils/constantList'
import { getDramaList, setDramaShelf } from '@/api/operate'

// 基本信息组件实例
let RefBaseInfo = ref(null)
// 剧集组件实例
let RefSeries = ref(null)
// 转发设置组件实例
let RefForward = ref(null)
// 剧名
let name = ref(undefined)
// 评级
let gradeID = ref(undefined)
// 上架状态
let OnShelfStatus = ref(undefined)
// 首页展示
let isShow = ref(undefined)
// 广告解锁
let isAdUnlock = ref(undefined)
// 剧情分类
let plotType = ref(undefined)
// 排序
let sortKey = ref('weight')
// 排序项
let sortOptions = ref([
  {
    value: 'weight',
    text: '权重降序'
  },
  {
    value: 'id',
    text: '入库时间降序'
  },
])
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '剧集ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '评级',
    dataIndex: 'grade',
    key: 'grade'
  },
  {
    title: '单集收费',
    dataIndex: 'pay_fee',
    key: 'pay_fee'
  },
  // {
  //   title: '付费转化率',
  //   dataIndex: 'rate',
  //   key: 'rate'
  // },
  {
    title: '权重',
    dataIndex: 'weight',
    key: 'weight'
  },
  {
    title: '总集数',
    dataIndex: 'total_ep',
    key: 'total_ep'
  },
  {
    title: '授权开始日期',
    dataIndex: 'copyright_start_at',
    key: 'copyright_start_at'
  },
  {
    title: '授权结束日期',
    dataIndex: 'copyright_end_at',
    key: 'copyright_end_at'
  },
  {
    title: '上架状态',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: '首页展示',
    dataIndex: 'is_show',
    key: 'is_show'
  },
  {
    title: '广告解锁',
    dataIndex: 'ad_unlock',
    key: 'ad_unlock'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getData()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 多选处理
let selectedRowKeys = ref([])
let selectedRows = ref([])
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

// 重置
function handleReset () {
  // 筛选项重置
  name.value = undefined
  gradeID.value = undefined
  OnShelfStatus.value = undefined
  isShow.value = undefined
  isAdUnlock.value = undefined
  sortKey.value = 'weight'
  plotType.value = undefined
  // 分页重置
  pagination.current = 1
  getData()
}

// 获取数据
function getData () {
  isLoading.value = true
  const params = {
    in_storage: 1,
    name: name.value,
    grade: gradeID.value,
    male_and_female: plotType.value,
    status: OnShelfStatus.value,
    is_show: isShow.value,
    ad_unlock: isAdUnlock.value,
    key: sortKey.value,
    sort: 'desc',
    page: pagination.current,
    page_size: pagination.pageSize
  }
  getDramaList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      // 清空多选状态
      selectedRowKeys.value = []
      selectedRows.value = []
    } else {
      message.error(res.message || msg)
    }
  })
}

// 批量上下架
function batchShelves (type) {
  isLoading.value = true
  setDramaShelf({
    type,
    ids: selectedRowKeys.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('操作成功')
      getData()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 基本信息
function touchInfo (record) {
  RefBaseInfo.value.showDrawer(record)
}

// 剧集
function touchSeries (record) {
  RefSeries.value.showDrawer(record)
}

// 转发设置
function touchForward (record) {
  RefForward.value.showDrawer(record)
}
</script>

<style scoped>
.cover-img {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.cover-title {
  margin-left: 10px;
}
</style>