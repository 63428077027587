import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 内容配置 》

// 获取剧情配置列表
export function getAllClassifyList(parameter) {
  return axios({
    url: BASE_URL + '/all_classify_list',
    method: 'get',
    params: parameter
  })
}

// 新增分类配置
export function allClassifyAdd(parameter) {
  return axios({
    url: BASE_URL + `/store/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

// 更新分类配置
export function allClassifyUpdate(parameter) {
  return axios({
    url: BASE_URL + `/update/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

// 移动分类配置
export function allClassifySort(parameter) {
  return axios({
    url: BASE_URL + `/sort/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

// 开关分类配置
export function allClassifySwitch(parameter) {
  return axios({
    url: BASE_URL + `/switch/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

// ================================ 《 项目配置 》

// 项目列表
export function projectList(parameter) {
  return axios({
    url: BASE_URL + '/project/list',
    method: 'get',
    params: parameter
  })
}

// 新建、编辑项目
export function projectEdit(parameter) {
  return axios({
    url: BASE_URL + `/project/edit`,
    method: 'post',
    data: parameter
  })
}

// 项目-开发设置-详情
export function projectDevelopmentDetail(parameter) {
  return axios({
    url: BASE_URL + '/project/config',
    method: 'get',
    params: parameter
  })
}

// 项目-开发设置-编辑
export function projectDevelopmentEdit(parameter) {
  return axios({
    url: BASE_URL + `/project/platform_save`,
    method: 'post',
    data: parameter
  })
}

// 全部配置列表（用于下拉选择）
export function projectConfigAll(parameter) {
  return axios({
    url: BASE_URL + '/project/config_all',
    method: 'get',
    params: parameter
  })
}

// 配置管理列表
export function projectConfigList(parameter) {
  return axios({
    url: BASE_URL + `/project/config_list`,
    method: 'get',
    params: parameter
  })
}

// 配置详情
export function projectConfigDetail(parameter) {
  return axios({
    url: BASE_URL + `/project/config_detail`,
    method: 'get',
    params: parameter
  })
}

// 配置添加、修改
export function projectConfigSave(parameter) {
  return axios({
    url: BASE_URL + `/project/config_save`,
    method: 'post',
    data: parameter
  })
}

// 项目状态修改
export function projectStatus(parameter) {
  return axios({
    url: BASE_URL + `/project/project_status`,
    method: 'post',
    data: parameter
  })
}

// 切换首页AB版
export function projectAbShow(parameter) {
  return axios({
    url: BASE_URL + `/project/project_ab_show`,
    method: 'post',
    data: parameter
  })
}

// 切换网赚模式开关
export function projectWzShow(parameter) {
  return axios({
    url: BASE_URL + `/project/project_wz_close`,
    method: 'post',
    data: parameter
  })
}